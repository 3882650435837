export const HapticsService = {
  notificationSuccess: () => {
    return Promise.resolve();
  },
  notificationWarning: () => {
    return Promise.resolve();
  },
  notificationError: () => {
    return Promise.resolve();
  },
  impactLight: () => {
    return Promise.resolve();
  },
  impactMedium: () => {
    return Promise.resolve();
  },
  impactHeavy: () => {
    return Promise.resolve();
  },
  selection: () => {
    return Promise.resolve();
  },
};
