import { type Dispatch } from "react";

import { type Action, type User } from "./authState";
import { type IdentityCheckHookResult } from "./types";

export const isIdentityCheckAvailable = async (): Promise<boolean> => {
  return Promise.resolve(false);
};

export const useIdentityCheck = (
  _logout: () => Promise<void>,
  _dispatch: Dispatch<Action<User>>,
  _isReady: boolean,
  _isHydrated: boolean,
  _isAuthenticated: boolean,
  _lastActiveTime: number | null,
  _hasRegisteredBiometrics: boolean,
  _isIdentityChecked: boolean,
  _identityCheckError: string | null,
): IdentityCheckHookResult => {
  return {
    identityCheck: {
      isReady: true,
      securityLevel: "none",
      facialRecognitionAvailable: false,
      fingerprintAvailable: false,
      irisAvailable: false,
      check: async () => Promise.resolve(true),
    },
  };
};
