import React from "react";
import { AlertDialog, Button, type DialogProps, XStack, YStack } from "tamagui";

export type AlertDialogNotificationProps = {
  title: string;
  message: string;
  // defaults to Ok
  acceptText?: string;
  // defaults to Cancel
  cancelText?: string;
  // defaults to true
  native?: boolean;
  // defaults to false
  cancellable?: boolean;
  // defaults to false (if true, the cancel button will be thin)
  cancelThin?: boolean;
  onAccept?: () => void;
};

/*
 * Implements a simple native AlertDialog
 * see https://tamagui.dev/docs/components/alert-dialog
 */
export const AlertDialogNotification = ({
  title,
  message,
  acceptText = "Ok",
  native = true,
  open,
  onOpenChange,
  onAccept,
  cancellable = false,
  cancelThin = false,
}: AlertDialogNotificationProps & DialogProps) => {
  return (
    <AlertDialog native={native} open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger />
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          key="overlay"
          animation="quick"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <AlertDialog.Content
          bordered
          elevate
          key="content"
          animation={[
            "quick",
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          x={0}
          scale={1}
          opacity={1}
          y={0}
        >
          <YStack space>
            <AlertDialog.Title>{title}</AlertDialog.Title>
            <AlertDialog.Description>{message}</AlertDialog.Description>
            <XStack space="$3" justifyContent="flex-end">
              {cancellable ? (
                cancelThin ? (
                  <AlertDialog.Action asChild onPress={() => onOpenChange}>
                    <Button theme="active">Cancel</Button>
                  </AlertDialog.Action>
                ) : (
                  <AlertDialog.Cancel asChild>
                    <Button theme="active">Cancel</Button>
                  </AlertDialog.Cancel>
                )
              ) : null}
              <AlertDialog.Action asChild onPress={onAccept}>
                <Button theme="active">{acceptText}</Button>
              </AlertDialog.Action>
            </XStack>
          </YStack>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog>
  );
};
