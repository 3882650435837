import React from "react";
import {
  Circle,
  Paragraph,
  type StackProps,
  type TextProps,
  type ThemeName,
  XStack,
} from "tamagui";

import { LinearGradient } from "@tamagui/linear-gradient";

import { validToken } from "../validToken";

export type IssueStatus = "CLOSED" | "NEW_ACTIVITY" | "PROCESSING";

export const getChatPillStyle = (status: IssueStatus): PillProps => {
  switch (status) {
    case "CLOSED":
      return { text: "Resolved", notificationDot: false, theme: "alt2" };
    case "NEW_ACTIVITY":
      return {
        text: "New",
        notificationDot: true,
        gradientColors: ["#FFE0F5", "#E1EDFF"],
        textColor: "$blue12",
        theme: "blue",
      };
    default:
      return {
        text: "Processing",
        notificationDot: false,
        gradientColors: ["#F3FFD0", "#D5FFE2"],
        textColor: "$green12",
        theme: "green",
      };
  }
};

export type PillProps = {
  text: string;
  gradientColors?: string[];
  textColor?: string;
  notificationDot?: boolean;
  theme?: ThemeName;
  wrapperProps?: StackProps;
  textProps?: TextProps;
};

export const Pill = ({
  text,
  gradientColors,
  textColor,
  notificationDot,
  theme = "alt1",
  wrapperProps,
  textProps,
}: PillProps) => {
  return (
    <XStack
      theme={theme}
      ai="center"
      jc="space-evenly"
      br="$12"
      p="$2"
      bg="$color1"
      {...wrapperProps}
    >
      {gradientColors ? (
        <LinearGradient
          colors={gradientColors}
          start={{ x: 0, y: 0 }}
          end={{ x: 0.4, y: 1 }}
          br="$12"
          pos="absolute"
          zi={1}
          fullscreen
        ></LinearGradient>
      ) : null}
      {notificationDot ? (
        <Circle size="$0.75" ml="$2" zi={2}>
          <LinearGradient
            br="$6"
            colors={["#67A6FF", "#FC4ECA"]}
            start={{ x: -0.5, y: 0 }}
            end={{ x: 0.7, y: 0.5 }}
            pos="absolute"
            fullscreen
          />
        </Circle>
      ) : null}
      <Paragraph
        size="$1"
        px="$2"
        color={validToken(textColor)}
        zi={2}
        {...textProps}
      >
        {text}
      </Paragraph>
    </XStack>
  );
};
