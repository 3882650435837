export type ClientErrorHandling =
  | "error-screen"
  | "caller"
  | "no-log"
  | "ignore";

// Used to denote the type of error handling that the client should use
// for a given query/mutation. Pass the result to the `context` property
// in the options for `useQuery` or a mutation call, e.g.:
//
// const { loading, data, error } = useQuery(query, {
//   context: errorHandling("caller"),
// });
//
// Note that authentication errors are handled separately, and are typically
// not returned to the caller.
//
// Network errors are never logged, but should still be handled by the caller.
//
// error-screen: The most aggressive error-handling behavior - useful as
//   a sensible default if you are not sure which to use - and the default
//   if no strategy is specified. GQL errors are logged, and the user is
//   punted to the error screen. Use this for:
//     - Operations that are critical to the core app behavior, and should
//       never fail. A failure is considered a bug, and the user should
//       not be allowed to continue.
//     - Operations where errors are NOT explicitly handled by the caller.
//
// caller: The caller handles errors (both network and GQL) explicitly.
//   GQL errors are logged, but do not result in the user being punted to
//   the error screen. Use this for:
//     - Operations that are critical to the core app behavior, where we
//       want to use the default error logging behavior for visibility, and
//       are handling the error explicitly in the caller.
//     - Queries must explicitly check the `error` property on the result.
//     - Mutations must explicitly be wrapped in a try/await/catch block.
//
// no-log: The caller handles errors explicitly, and does not want them
//   logged by the client. Use this for:
//     - Operations that are not critical to the core app behavior, and we
//       don't care about failures. e.g. a query for non-essential metadata.
//     - Operations that have explicit error handling, will log errors on
//       their own, and the default logging behavior is not desired.
export const errorHandling = (strategy?: ClientErrorHandling) => ({
  strategy,
});
