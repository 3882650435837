import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect } from "react";

import { useIssueEvents } from "@medbillai/app/provider/issue-events";

import { gql } from "@medbillai/graphql-types";

import { FullscreenSpinner, isWeb, useFocusEffect } from "@medbillai/ui";

import { ScreenView, type ScreenViewProps } from "../../components/ScreenView";
import { errorHandling } from "../../lib/apollo/utils";
import { useCasesStore } from "../../lib/cases/casesStore";
import { CaseList } from "./components/CaseList";
import { EmptyCaseList } from "./components/EmptyCaseList";

export const getIssuesQuery = gql(/* GraphQL */ `
  query GetIssues {
    me {
      id
      issues(order: { lastEvent: { sentAt: { dir: DESC } } }) {
        __typename
        id
        title
        openedAt
        closedAt
        lastEvent {
          __typename
          id
          sentAt
          eventType
          participant
          ... on issue__ChatEvent_Type {
            chatBody
          }
          ... on issue__TemplateReleaseRequestedEvent_Type {
            template {
              description
            }
          }
        }
        firstUnseenEvent {
          __typename
          id
          sentAt
          eventType
          ... on issue__ChatEvent_Type {
            chatBody
          }
          ... on issue__TemplateReleaseRequestedEvent_Type {
            template {
              description
            }
          }
        }
      }
    }
  }
`);

export type CasesScreenProps = {
  screenViewProps?: Omit<ScreenViewProps, "children">;
};

export const CasesScreen = ({ screenViewProps }: CasesScreenProps) => {
  const { loading, data, error, refetch } = useQuery(getIssuesQuery, {
    context: errorHandling("error-screen"),
  });
  const { setIsEmpty } = useCasesStore();
  const { refetchUnreadIssuesCount } = useIssueEvents();

  useFocusEffect(
    useCallback(() => {
      void doRefresh();
    }, []),
  );

  useEffect(() => {
    setIsEmpty(!data?.me?.issues?.length);
  }, [data?.me?.issues]);

  const doRefresh = async () => {
    await refetch();
    refetchUnreadIssuesCount();
  };

  const issues = data?.me?.issues;

  const isEmpty = !issues?.length;

  return (
    <ScreenView noPadding={!isWeb} onRefresh={doRefresh} {...screenViewProps}>
      {loading || error ? (
        <FullscreenSpinner />
      ) : isEmpty ? (
        <EmptyCaseList />
      ) : (
        <CaseList issues={issues} />
      )}
    </ScreenView>
  );
};
