const primary_blue = [
  "#F2F5FF40", // background color F2F5FF at 25% opacity
  "#F2F5FF80", // background color F2F5FF at 50% opacity
  "#F2F5FFBF", // background color F2F5FF at 75% opacity
  "#F2F5FF", // background color F2F5FF
  "#79829D40", // border color 79829D at 25% opacity
  "hsl(210, 50.0%, 30.0%)", // color 6
  "hsl(210, 60.0%, 20.0%)", // color 7
  "hsl(210, 70.0%, 10.0%)", // color 8
  "hsl(210, 80.0%, 10.0%)", // color 9
  "hsl(210, 90.0%, 10.0%)", // color 10
  "hsl(210, 100%, 10.0%)", // color 11
  "#79829D", // text color for the placeholder 79829D
];

// Custom Blue Theme
export const primaryBlue = {
  blue1: primary_blue[0] as string,
  blue2: primary_blue[1] as string,
  blue3: primary_blue[2] as string,
  blue4: primary_blue[3] as string,
  blue5: primary_blue[4] as string,
  blue6: primary_blue[5] as string,
  blue7: primary_blue[6] as string,
  blue8: primary_blue[7] as string,
  blue9: primary_blue[8] as string,
  blue10: primary_blue[9] as string,
  blue11: primary_blue[10] as string,
  blue12: primary_blue[11] as string,
};
