import {
  type PushNotificationResponse,
  type PushNotificationsConfig,
  type PushNotificationsServiceInterface,
  PushPermissionStatus,
} from "./types";

/**
 * These are the web versions of these functions and are not
 * used: Push notifications are not supported on web
 *
 * See: push-notifications.native.ts
 */
export const pushNotificationsService: PushNotificationsServiceInterface = {
  // See customerio notifications setup documentation at:
  // https://customer.io/docs/journeys/push-getting-started/
  initialize: (_config: PushNotificationsConfig) => {
    console.debug("Push notifications not available on web");
  },
  showPermissionStatusPrompt: async (): Promise<PushNotificationResponse> => {
    return Promise.resolve({
      success: true,
      permissionStatus: PushPermissionStatus.Denied,
    });
  },
  getPermissionStatus: async (): Promise<PushNotificationResponse> => {
    return Promise.resolve({
      success: true,
      permissionStatus: PushPermissionStatus.Denied,
    });
  },
  deregisterDevice: () => {},
  registerDevice: (_email: string, _userId: string) => {},
};
