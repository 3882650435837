import React from "react";
import { useRouter } from "solito/router";

import { MessagesSquare as MessagesSquareIcon } from "@tamagui/lucide-icons";

import { IssueType } from "@medbillai/graphql-types";

import { LoadingButton, Paragraph, YStack } from "@medbillai/ui";

import { issuePreviewUrl } from "@medbillai/utils/urls";

import { ShadowCircleHeader } from "../../../components/ShadowCircleHeader";
import { analyticsService } from "../../../lib/analytics";

export const EmptyCaseList = () => {
  const { push } = useRouter();
  const pushNewIssue = () => {
    analyticsService.trackEvent("ClickedNewChatEventbutton", {});
    push(issuePreviewUrl(IssueType.ONBOARDING));
  };

  return (
    <YStack jc="center" ai="center" f={1} gap="$4" p="$4" $sm={{ gap: "$2" }}>
      <ShadowCircleHeader>
        <MessagesSquareIcon strokeWidth={1} size={50} />
      </ShadowCircleHeader>
      <Paragraph w="55%" m="$6" theme="alt2" ta="center">
        You will see cases here after your first request.
      </Paragraph>
      <LoadingButton onPress={pushNewIssue} w="50%">
        Start
      </LoadingButton>
    </YStack>
  );
};
