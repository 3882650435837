import { createTamagui, setupDev } from "tamagui";

import { shorthands } from "@tamagui/shorthands";

import { animations } from "./config/animations";
import { bodyFont, headingFont } from "./config/fonts";
import { media, mediaQueryDefaultActive } from "./config/media";
import * as themesIn from "./themes/theme-generated";
import { tokens } from "./themes/tokens";

// Hold down Option for a second to see some helpful visuals
setupDev({
  visualizer: true,
});

/**
 * This avoids shipping themes as JS. Instead, Tamagui will hydrate them from CSS.
 */
const themes =
  process.env.TAMAGUI_TARGET !== "web" || process.env.TAMAGUI_IS_SERVER
    ? themesIn
    : ({} as typeof themesIn);

export const config = createTamagui({
  themes,
  defaultFont: "body",
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  mediaQueryDefaultActive,
  selectionStyles: theme => ({
    backgroundColor: theme.color5,
    color: theme.color11,
  }),
  shorthands,
  fonts: {
    heading: headingFont,
    body: bodyFont,
  },
  tokens,
  media,
  settings: {
    allowedStyleValues: "somewhat-strict",
    autocompleteSpecificTokens: "except-special",
    fastSchemeChange: true,
  },
});

export default config;
