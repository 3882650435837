import { createInterFont } from "@tamagui/font-inter";

import { fontSize } from "./constants";

const headingScale = 1;
const bodyScale = 1.1;
const lineHeightScale = 1.5;

export const headingFont = createInterFont(
  {
    size: fontSize,
    weight: {
      // Semibold
      3: "600",
      // Bold
      4: "700",
      // All named headings (H1/H2/etc) are bold.
    },
    face: {
      700: { normal: "InterBold" },
    },
  },
  {
    sizeSize: size => Math.round(size * headingScale),
    sizeLineHeight: size => size * headingScale * lineHeightScale,
  },
);

export const bodyFont = createInterFont(
  {
    size: fontSize,
    face: {
      700: { normal: "InterBold" },
    },
  },
  {
    sizeSize: size => Math.round(size * bodyScale),
    sizeLineHeight: size => size * bodyScale * lineHeightScale,
  },
);
