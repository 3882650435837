import React from "react";
import { Circle, YStack } from "tamagui";

import { GradientUnderlayImage } from "./local-images";

interface ShadowCircleHeaderProps {
  children?: React.ReactNode;
}

export function ShadowCircleHeader({ children }: ShadowCircleHeaderProps) {
  return (
    <YStack alignItems="center">
      <Circle
        size="$10"
        backgroundColor="white"
        borderColor="$gray6"
        borderWidth="$0.5"
        zIndex={2}
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Circle>
      <GradientUnderlayImage marginTop={-80} position="relative" />
    </YStack>
  );
}
