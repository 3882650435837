export enum PushPermissionStatus {
  Granted = "Granted",
  Denied = "Denied",
  NotDetermined = "NotDetermined",
}

type PushNotificationResponseSuccess = {
  success: true;
  permissionStatus: PushPermissionStatus;
};

type PushNotificationResponseError = {
  success: false;
  message: string;
};

export type PushNotificationResponse =
  | PushNotificationResponseSuccess
  | PushNotificationResponseError;

export type ShowPromptProps = {
  redirect?: () => void;
};

export type PushNotificationsPromptsProps = {
  redirect?: () => void;
  permissionStatus: PushPermissionStatus;
};

export type PushNotificationsConfig = {
  customerioSiteId: string;
  customerioApiKey: string;
};

export type PushNotificationsServiceInterface = {
  initialize: (config: PushNotificationsConfig) => void;
  showPermissionStatusPrompt: () => Promise<PushNotificationResponse>;
  getPermissionStatus: () => Promise<PushNotificationResponse>;
  deregisterDevice: () => void;
  registerDevice: (email: string, userId: string) => void;
};
