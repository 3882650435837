import React from "react";

import { type Flag } from "@medbillai/utils";

// TODO: Implement stubs using LaunchDarkly React Web SDK:
// https://docs.launchdarkly.com/sdk/client-side/react/react-web
// For now, we just return the default flag value always.

export interface FlagProviderProps {
  children?: React.ReactNode;
  sdkKey?: string;
}

export const FlagProvider = ({ children }: FlagProviderProps) => {
  return <>{children}</>;
};

export const useLaunchDarklyIdentify = (
  _context: Record<string, unknown>,
) => {};

export const useFlagBool = (_flag: Flag, defaultValue: boolean) => {
  return defaultValue;
};

export const useFlagString = (_flag: Flag, defaultValue: string) => {
  return defaultValue;
};

export const useFlagNumber = (_flag: Flag, defaultValue: number) => {
  return defaultValue;
};
