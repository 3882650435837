import { create } from "zustand";

export interface StoreState {
  isEmpty: boolean;
}

export type Action = {
  setIsEmpty: (isEmpty: boolean) => void;
};

export const useCasesStore = create<StoreState & Action>(set => ({
  isEmpty: true,
  setIsEmpty: isEmpty => set({ isEmpty }),
}));
