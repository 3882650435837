import { useEffect, useState } from "react";
import { AppState } from "react-native";

type AppStateVisibility =
  | "active"
  | "background"
  | "inactive"
  | "unknown"
  | "extension";

type UseAppRefreshProps = {
  appState: AppStateVisibility;
  isActive: boolean;
};

/**
 * This hook is used to detect when the app is in the background or foreground.
 * Expressed as a boolean via the `isActive` prop(true if entering, false otherwise).
 * It also returns the current app state visibility if you need to use it for other
 * purposes.
 *
 * active - The app is running in the foreground
 * background - The app is running in the background.
 * [iOS] inactive - This is a state that occurs when transitioning between foreground & background, and
 *    during periods of inactivity such as entering the multitasking view, opening the Notification Center or
 *    in the event of an incoming call.
 * [Android] unknown - The app is in an unknown state
 */
export function useAppState(): UseAppRefreshProps {
  const [appState, setAppState] = useState(AppState.currentState);
  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      setAppState(nextAppState);
    });
    return () => {
      subscription.remove();
    };
  }, []);
  return {
    appState,
    isActive: appState == "active",
  };
}
