import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useMemo } from "react";

import { gql } from "@medbillai/graphql-types";

import { usePathname } from "@medbillai/ui";

import { errorHandling } from "../../lib/apollo/utils";
import { useAuth } from "../../lib/auth";
import { useAppState } from "../../lib/useAppState";

export const getUnreadIssuesCountQuery = gql(/* GraphQL */ `
  query GetUnreadIssues {
    me {
      id
      issues(
        filter: {
          firstUnseenEvent: { exists: true }
          closedAt: { exists: false }
        }
      ) {
        id
        firstUnseenEvent {
          id
        }
      }
    }
  }
`);

interface IssueEventsContextInterface {
  unreadIssuesCount: number;
  refetchUnreadIssuesCount: () => void;
}

const initialContext: IssueEventsContextInterface = {
  unreadIssuesCount: 0,
  refetchUnreadIssuesCount: () => {
    throw new Error("Context not initialized");
  },
};

const IssueEventsContext =
  createContext<IssueEventsContextInterface>(initialContext);

export interface IssueEventsProviderProps {
  children: React.ReactNode;
}

/**
 * Keeps count of unread Issues and provides a function to refetch the count.
 * This will need to be migrated eventually to a subscription to support real-time updates
 * and in the meantime, we should consider adding a polling mechanism to keep the count up to date.
 */
export function IssueEventsProvider({ children }: IssueEventsProviderProps) {
  const { isAuthenticated } = useAuth();
  const { data, refetch } = useQuery(getUnreadIssuesCountQuery, {
    context: errorHandling("caller"),
    skip: !isAuthenticated,
  });
  const { isActive } = useAppState();
  const pathName = usePathname();

  const refetchUnreadIssuesCount = () => {
    void (async () => {
      try {
        await refetch();
      } catch {
        // Errors automatically logged to Sentry by the Apollo client
      }
    })();
  };

  // Poll for unread issues based on appstate and authentication
  useEffect(() => {
    if (isActive && isAuthenticated) {
      refetchUnreadIssuesCount();
    }
  }, [isActive, isAuthenticated, pathName]);

  const contextValue = useMemo<IssueEventsContextInterface>(
    () => ({
      unreadIssuesCount: data?.me?.issues?.length ?? 0,
      refetchUnreadIssuesCount,
    }),
    [data?.me?.issues?.length, refetchUnreadIssuesCount],
  );

  return (
    <IssueEventsContext.Provider value={contextValue}>
      {children}
    </IssueEventsContext.Provider>
  );
}

export const useIssueEvents = (
  context = IssueEventsContext,
): IssueEventsContextInterface => useContext(context);
