// Loosely based on: https://github.com/auth0/auth0-react/
import { createContext } from "react";

import {
  type AuthUserResult,
  type AuthenticateMagicLinkResult,
  type SendMagicLinkResult,
} from "@medbillai/graphql-types";

import { type AuthState, type User, initialAuthState } from "./authState";
import { type IdentityCheck } from "./types";

export interface AuthContextInterface<TUser extends User = User>
  extends AuthState<TUser> {
  sendMagicLink: (email: string) => Promise<SendMagicLinkResult>;
  authenticateMagicLink: (
    token: string,
  ) => Promise<AuthenticateMagicLinkResult>;
  logout: () => Promise<void>;
  shadowLogin: (email: string) => Promise<AuthUserResult | undefined>;
  identityCheck: IdentityCheck;
  isScreenLocked: boolean;
  isScreenLockSuppressed: boolean;
  disableScreenLock: () => void;
  enableScreenLock: () => void;
}

export const initialIdentityCheck: IdentityCheck = {
  isReady: false,
  securityLevel: "none",
  facialRecognitionAvailable: false,
  fingerprintAvailable: false,
  irisAvailable: false,
  // Checks biometrics identity.
  check: () => {
    throw new Error("Context not initialized");
  },
};

export const initialContext: AuthContextInterface = {
  ...initialAuthState,
  identityCheck: initialIdentityCheck,
  isScreenLocked: false,
  isScreenLockSuppressed: false,
  // Functions will be set by AuthProvider upon instantiation

  // sendMagicLink sends a magic login link to the given email address.
  sendMagicLink: () => {
    throw new Error("Context not initialized");
  },
  // authenticateMagicLink authenticates the user with the given magic link token.
  authenticateMagicLink: () => {
    throw new Error("Context not initialized");
  },
  // logout logs the user out through the API. The user's session cookie is cleared as a result.
  logout: () => {
    throw new Error("Context not initialized");
  },
  // shadowLogin allows an admin user to convert their session to impersonate another user.
  shadowLogin: () => {
    throw new Error("Context not initialized");
  },
  disableScreenLock: () => {
    throw new Error("Context not initialized");
  },
  enableScreenLock: () => {
    throw new Error("Context not initialized");
  },
};

export const AuthContext = createContext<AuthContextInterface>(initialContext);
