import {
  type ApolloClient,
  ApolloProvider as ApolloProviderOG,
  type NormalizedCacheObject,
} from "@apollo/client";
import React, { type ReactNode, useState } from "react";

import { createApolloClient } from "../../lib/apollo";

export type ApolloProviderProps = {
  apolloPath: string;
  apolloWsPath: string;
  rethrowError?: (error: Error) => void;
  onAuthError?: () => Promise<void>;
  children: ReactNode;
};

export const ApolloProvider = ({
  apolloPath,
  apolloWsPath,
  rethrowError,
  onAuthError,
  children,
}: ApolloProviderProps) => {
  const [apolloClient] = useState<ApolloClient<NormalizedCacheObject>>(
    createApolloClient(apolloPath, apolloWsPath, rethrowError, onAuthError),
  );

  return <ApolloProviderOG client={apolloClient}>{children}</ApolloProviderOG>;
};
