import React from "react";

import { Image, type ImageProps } from "@tamagui/image";

export const BlurBackgroundImage = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/gradientBackground.png`,
      }}
      {...props}
    />
  );
};

export const GradientUnderlayImage = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/gradient-underlay.png`,
      }}
      height={100}
      width={150}
      {...props}
    />
  );
};

export const GradientBackgroundImage = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/gradientOverlayBackground.png`,
      }}
      {...props}
    />
  );
};
