const stateStoragePrefix = "medbillai-auth-state";

export const setItem = async (key: string, value: string) => {
  localStorage.setItem(`${stateStoragePrefix}-${key}`, value);
  return Promise.resolve();
};

export const getItem = async (key: string) => {
  return Promise.resolve(localStorage.getItem(`${stateStoragePrefix}-${key}`));
};

export const removeItem = async (key: string) => {
  localStorage.removeItem(`${stateStoragePrefix}-${key}`);
  return Promise.resolve();
};
