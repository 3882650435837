/*
 * Handpick of the Tamagui themes
 * Source : https://github.com/tamagui/tamagui/blob/8186a1ca27c79549d69a3c68fd168505796c5abd/packages/themes/src/componentThemeDefinitions.tsx
 * See their guide for custom theme creation : https://tamagui.dev/docs/guides/theme-builder
 */
import { type Variable, createTokens } from "tamagui";

import {
  blueDark,
  gray,
  grayDark,
  green,
  greenDark,
  orange,
  orangeDark,
  pink,
  pinkDark,
  purple,
  purpleDark,
  red,
  redDark,
  yellow,
  yellowDark,
} from "@tamagui/colors";

import { fontSize } from "../config/constants";
import { primaryBlue } from "./customColors";

// should roughly map to button/input etc height at each level
// fonts should match that height/lineHeight at each stop
// so these are really non-linear on purpose
// why?
//   - at sizes <1, used for fine grained things (borders, smallest paddingY)
//     - so smallest padY should be roughly 1-4px so it can join with lineHeight
//   - at sizes >=1, have to consider "pressability" (jumps up)
//   - after that it should go upwards somewhat naturally
//   - H1 / headings top out at 10 naturally, so after 10 we can go upwards faster
//  but also one more wrinkle...
//  space is used in conjunction with size
//  i'm setting space to generally just a fixed fraction of size (~1/3-2/3 still fine tuning)
export const size = {
  $0: 0,
  "$0.25": 2,
  "$0.5": 4,
  "$0.75": 8,
  $1: 20,
  "$1.5": 24,
  $2: 28,
  "$2.5": 32,
  $3: 36,
  "$3.5": 40,
  $4: 44,
  $true: 44,
  "$4.5": 48,
  $5: 52,
  "$5.5": 58,
  $6: 64,
  $7: 74,
  $8: 84,
  $9: 94,
  $10: 104,
  $11: 124,
  $12: 144,
  $13: 164,
  $14: 184,
  $15: 204,
  $16: 224,
  $17: 224,
  $18: 244,
  $19: 264,
  $20: 284,
};

type SizeKeysIn = keyof typeof size;
type Sizes = {
  [Key in SizeKeysIn extends `$${infer KeyName}`
    ? KeyName
    : SizeKeysIn]: number;
};
type SizeKeys = `${keyof Sizes extends `${infer K}` ? K : never}`;

const spaces = Object.entries(size).map(([k, v]) => {
  return [k, sizeToSpace(v)] as const;
});

// a bit odd but keeping backward compat for values >8 while fixing below
function sizeToSpace(v: number) {
  if (v === 0) {
    return 0;
  }
  if (v === 2) {
    return 0.5;
  }
  if (v === 4) {
    return 1;
  }
  if (v === 8) {
    return 1.5;
  }
  if (v <= 16) {
    return Math.round(v * 0.333);
  }
  return Math.floor(v * 0.7 - 12);
}

const spacesNegative = spaces.slice(1).map(([k, v]) => [`-${k.slice(1)}`, -v]);

type SizeKeysWithNegatives =
  | Exclude<`-${SizeKeys extends `$${infer Key}` ? Key : SizeKeys}`, "-0">
  | SizeKeys;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const space: {
  [Key in SizeKeysWithNegatives]: Key extends keyof Sizes ? Sizes[Key] : number;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
} = {
  ...Object.fromEntries(spaces),
  ...Object.fromEntries(spacesNegative),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

export type SpaceKeys = keyof typeof space;

export type FontKeys = keyof typeof fontSize;

export const zIndex = {
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
};

export const colorTokens = {
  light: {
    blue: primaryBlue,
    gray,
    green,
    orange,
    pink,
    purple,
    red,
    yellow,
    primaryBlue,
  },
  dark: {
    blue: blueDark,
    gray: grayDark,
    green: greenDark,
    orange: orangeDark,
    pink: pinkDark,
    purple: purpleDark,
    red: redDark,
    yellow: yellowDark,
    primaryBlue,
  },
  primary: {
    inputBackground: "#79829D7D",
    inputBorder: "#F2F5FF",
    inputTextColor: "#79829D",
    softBlack: "#272735",
    textBlack: "#2B0E44",
    textSoftGray: "#5E5E5E",
  },
};

export const darkColors = {
  ...colorTokens.dark.blue,
  ...colorTokens.dark.gray,
  ...colorTokens.dark.green,
  ...colorTokens.dark.orange,
  ...colorTokens.dark.pink,
  ...colorTokens.dark.purple,
  ...colorTokens.dark.red,
  ...colorTokens.dark.yellow,
  ...colorTokens.dark.primaryBlue,
};

export const lightColors = {
  ...colorTokens.light.blue,
  ...colorTokens.light.gray,
  ...colorTokens.light.green,
  ...colorTokens.light.orange,
  ...colorTokens.light.pink,
  ...colorTokens.light.purple,
  ...colorTokens.light.red,
  ...colorTokens.light.yellow,
  ...colorTokens.light.primaryBlue,
};

export const primaryColors = {
  ...colorTokens.primary,
};

export const color = {
  ...postfixObjKeys(lightColors, "Light"),
  ...postfixObjKeys(darkColors, "Dark"),
  ...postfixObjKeys(colorTokens.primary, "Primary"),
};

function postfixObjKeys<
  A extends { [key: string]: Variable<string> | string },
  B extends string,
>(
  obj: A,
  postfix: B,
): {
  [Key in `${keyof A extends string ? keyof A : never}${B}`]:
    | Variable<string>
    | string;
} {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [`${k}${postfix}`, v]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
}

export const radius = {
  0: 0,
  1: 3,
  2: 5,
  3: 7,
  4: 9,
  true: 9,
  5: 10,
  6: 16,
  7: 19,
  8: 22,
  9: 26,
  10: 34,
  11: 42,
  12: 50,
};

export type RadiusKeys = keyof typeof radius;

export const tokens = createTokens({
  color,
  radius,
  zIndex,
  space,
  size,
  fontSize,

  // testing
  icon: {
    sm: 16,
    md: 24,
    lg: 32,
  },
});
