import { type ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { type GraphQLError } from "graphql";

import { type ClientErrorHandling } from "./utils";

// Some standard error types so we can check for specific errors in
// the error boundary.
export class ApolloNetworkError extends Error {
  constructor(
    message: string,
    public readonly networkError: Error,
  ) {
    super(message);
  }
}
export class ApolloGraphQLError extends Error {
  constructor(
    message: string,
    public readonly graphQLErrors: readonly Error[],
  ) {
    super(message);
  }
}

const ignoreAuthErrorsOperations = ["Profile", "Logout"];

// Ref: https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
// TODO: The onError function is not allowed to throw any errors of its own, or modify the response
// to add or remove errors. If we want finer-grained control, we should switch to a custom ApolloLink
// class.

export const errorLink = (
  rethrowError?: (error: Error) => void,
  onAuthError?: () => Promise<void>,
): ApolloLink =>
  onError(({ graphQLErrors, networkError, operation }) => {
    let authErrorCalled = false;
    const throwableErrors: GraphQLError[] = [];
    const errorHandling = (operation.getContext().errorHandling ??
      "error-screen") as ClientErrorHandling;

    if (graphQLErrors) {
      // As a general rule, we want GraphQL errors to fail loudly - push the user to an error
      // screen, send the error to Sentry, etc. This behavior can be tuned on a given query/mutation
      // by the caller through the use of the errorHandling context property.
      //
      // The exception is auth errors, which we just notify onAuthError for.

      for (const gqlErr of graphQLErrors) {
        const isAuthError = gqlErr.extensions?.code === "UNAUTHENTICATED";

        if (isAuthError) {
          // Ignore auth errors when fetching the profile - this is a special case query used by
          // the auth provider that we expect to fail if the session has expired. Any errors
          // are handled by the provider, and result in a hard logout action. Filtering here
          // avoids an unnecessary error log, and a duplicate redirect to the login page (via
          // onAuthError).
          // We also ignore errors on logout, as this may be called defensively when the user is
          // already logged out.
          if (ignoreAuthErrorsOperations.includes(operation.operationName)) {
            continue;
          }

          if (onAuthError && !authErrorCalled) {
            if (errorHandling !== "no-log") {
              console.error(
                `[Authentication error]: Calling onAuthError. Operation: ${operation.operationName}, Message: ${gqlErr.message}`,
              );
            }
            authErrorCalled = true;
            void onAuthError();
            // We allow this to continue through to the logging step below, so we have visibility
            // of the error in Sentry. But we don't throw an error (and send the user to an error
            // screen) since the user was likely logged out and redirected to the login page by
            // onAuthError.
          }
        }

        // Make sure to log any errors to Sentry.
        const { message, path, ...rest } = gqlErr;
        if (errorHandling !== "no-log") {
          console.error(
            `[GraphQL error]: Message: ${message}\n` +
              `Path: ${path?.join(".") ?? "unknown"}` +
              (Object.keys(rest).length
                ? `\nRest: ${JSON.stringify(rest)}`
                : ""),
          );
        }

        // Don't rethrow auth errors. We've already handled them above.
        if (!isAuthError || !onAuthError) {
          throwableErrors.push(gqlErr);
        }
      }

      if (
        throwableErrors.length > 0 &&
        errorHandling === "error-screen" &&
        rethrowError
      ) {
        // In error-screen handling mode, errors are not guaranteed to be handled by the caller, so
        // we escalate them up to the root component. Otherwise, they could be lost as unhandled
        // promise rejections. This ensures we trigger the error boundary, and pushes the user to an
        // error screen.
        rethrowError(new ApolloGraphQLError("GraphQL error", throwableErrors));
      }
    }
    if (networkError) {
      // Network errors are more unusual. This could indicate a problem with the server, or a
      // problem with the client's network connection. By the time it reaches this code, the client
      // has already retried the request a few times without success.
      // We never log these errors to Sentry, as they are most likely to be caused by a transient
      // change in network connectivity.

      const { message, ...rest } = networkError;
      if (errorHandling !== "no-log") {
        // By using a `warn` log level, we can see these errors in the console, but they don't
        // get pushed to Sentry.
        console.warn(
          `[Network error]: Operation: ${operation.operationName}, Message: ${message}` +
            (Object.keys(rest).length ? `\nRest: ${JSON.stringify(rest)}` : ""),
        );
      }

      if (
        errorHandling === "error-screen" &&
        rethrowError &&
        throwableErrors.length === 0
      ) {
        // If we already escalated some GraphQL errors, those are likely more specific/useful, so
        // we skip escalating the network error in that case.
        rethrowError(new ApolloNetworkError(message, networkError));
      }
    }
  });
