import React from "react";
import { useLink } from "solito/link";

import { type GetIssuesQuery } from "@medbillai/graphql-types";

import {
  CalendarText,
  type IssueStatus,
  Paragraph,
  Pill,
  XStack,
  YStack,
  getChatPillStyle,
  styled,
} from "@medbillai/ui";

import { issueUrl } from "@medbillai/utils/urls";

type ElementOf<T> = T extends (infer E)[] ? E : never;
type Issue = NonNullable<
  ElementOf<NonNullable<GetIssuesQuery["me"]>["issues"]>
>;
type CaseProps = {
  issue: Issue;
};

const DateTextStyle = styled(Paragraph, {
  size: "$2",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  textAlign: "left",
});

const getTimeColor = (status: IssueStatus) => {
  return status === "NEW_ACTIVITY" ? "$blue9" : "$gray9";
};

const formatEvent = (event: Issue["lastEvent"] | Issue["firstUnseenEvent"]) => {
  switch (event?.__typename) {
    case "issue__ChatEvent_Type":
      return event.chatBody;
    case "issue__ClosedEvent_Type":
      return "Issue closed";
    case "issue__DocumentUploadedEvent_Type":
      return "Document uploaded";
    case "issue__ReopenedEvent_Type":
      return "Issue reopened";
    case "issue__HipaaReleaseRequestedEvent_Type":
      return "HIPAA release requested";
    case "issue__TemplateReleaseRequestedEvent_Type":
      return `${event.template.description} requested`;
    case "issue__BillDisputeEvent_Type":
      return "Bill disputed";
    default:
      return null;
  }
};

const cleanMarkdownText = (text: string | null) => {
  if (!text) {
    return "";
  }
  return text.replace(/[\\^$*+?.()|[\]{}#]/g, "");
};

const CaseListItem = ({ issue }: CaseProps) => {
  // If a unseen event exists we know there was new activity on the issue
  const status = issue.closedAt
    ? "CLOSED"
    : issue.firstUnseenEvent
      ? "NEW_ACTIVITY"
      : "PROCESSING";

  const lastEvent = issue.lastEvent;

  // First check if firstUnseenEvent is available,
  // if available display that event formatted,
  // otherwise display most recent event formatted
  const eventContent = formatEvent(issue.firstUnseenEvent || lastEvent);

  return (
    <YStack
      {...useLink({ href: issueUrl(issue.id) })}
      cur="pointer"
      bg="$color1"
      p="$4"
      px="$4"
      gap="$3"
      borderBottomWidth={0.25}
      borderBottomColor="$color6"
      pressStyle={{
        backgroundColor: "$color4",
      }}
    >
      <XStack gap="$4" ai="center" jc="space-between" py="$1">
        <Paragraph fow="bold" f={1} numberOfLines={1}>
          {issue.title}
        </Paragraph>
        <Pill {...getChatPillStyle(status)} />
      </XStack>
      {lastEvent && (
        <XStack gap="$4" ai="center" jc="space-between">
          <Paragraph theme="alt2" f={1} numberOfLines={1}>
            {cleanMarkdownText(eventContent)}
          </Paragraph>
          <CalendarText
            date={lastEvent.sentAt}
            element={props => (
              <DateTextStyle {...props} color={getTimeColor(status)} />
            )}
          />
        </XStack>
      )}
    </YStack>
  );
};

type CaseListProps = {
  issues: Issue[];
};

export const CaseList = ({ issues }: CaseListProps) => {
  return (
    <YStack>
      {issues.map(issue => (
        <CaseListItem key={issue.id} issue={issue} />
      ))}
    </YStack>
  );
};
