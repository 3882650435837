import { BlurView } from "expo-blur";
import React from "react";
import { YStack } from "tamagui";

import { Toast, useToastState } from "@tamagui/toast";

export const NativeToast = () => {
  const currentToast = useToastState();

  if (!currentToast || currentToast.isHandledNatively) {
    return null;
  }

  return (
    <Toast
      key={currentToast.id}
      viewportName={currentToast.viewportName}
      enterStyle={{ opacity: 0, scale: 0.5, y: -25 }}
      exitStyle={{ opacity: 0, scale: 1, y: -20 }}
      y={0}
      opacity={1}
      scale={1}
      animation="quick"
      bc="transparent"
      borderRadius="$12"
      shadowColor="$color7"
      p={0}
      mx="$4"
      duration={currentToast.duration}
      borderColor="$color5"
      shadowRadius="$4"
      borderWidth={1}
    >
      <YStack
        overflow="hidden"
        // Values repeated with Toast because of the way overflow "hidden" works with BlurView and box shadow
        shadowRadius="$4"
        borderRadius="$12"
      >
        <BlurView
          tint="light"
          intensity={70}
          style={{
            overflow: "hidden",
          }}
        >
          <YStack px="$4.5" py="$4">
            <Toast.Title ta="center" fontWeight="700" pb="$1">
              {currentToast.title}
            </Toast.Title>
            {!!currentToast.message && (
              <Toast.Description ta="center" size="$4" color="$color11">
                {currentToast.message}
              </Toast.Description>
            )}
          </YStack>
        </BlurView>
      </YStack>
    </Toast>
  );
};
