import React from "react";
import Moment, { type MomentProps } from "react-moment";
import { Text } from "tamagui";

type DateProps = {
  date: MomentProps["date"] | null;
  utc?: MomentProps["utc"];
  missing?: string;
  element?: MomentProps["element"];
};

const MomentFormattedText = ({
  date,
  missing,
  format,
  element,
  utc,
}: DateProps & { format: string }) => {
  if (!date) {
    return missing ? <Text>{missing}</Text> : null;
  }
  return (
    <Moment element={element || Text} date={date} format={format} utc={utc} />
  );
};

export const CalendarText = ({ date, missing, element, utc }: DateProps) => {
  const calendar = {
    sameDay: "LT",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    lastYear: "MMM Do",
    sameElse: "MM/DD/YY",
  };
  if (!date) {
    return missing ? <Text>{missing}</Text> : null;
  }
  return (
    <Moment
      element={element || Text}
      date={date}
      calendar={calendar}
      utc={utc}
    />
  );
};

export const CalendarTextSimple = ({ date, missing, element }: DateProps) => {
  const calendar = {
    sameDay: "LT",
    lastWeek: "[Last] dddd",
    lastYear: "MMM Do",
    sameElse: "MM/DD/YY",
  };
  if (!date) {
    return missing ? <Text>{missing}</Text> : null;
  }
  return <Moment element={element || Text} date={date} calendar={calendar} />;
};

export const DayText = (props: DateProps) => {
  return <MomentFormattedText {...props} format="ddd, MMM D" />;
};

export const DayAndTimeText = (props: DateProps) => {
  return <MomentFormattedText {...props} format="MMM D, YYYY [at] HH:mmA" />;
};

export const DateText = ({
  format = "YYYY-MM-DD",
  ...props
}: DateProps & { format?: "YYYY-MM-DD" | "MMM D, YYYY" | "Do MMMM YYYY" }) => {
  return <MomentFormattedText {...props} format={format} />;
};

export const CompactDateText = (props: DateProps) => {
  return <MomentFormattedText {...props} format="MM/DD/YY" />;
};

export const TimeText = (props: DateProps) => {
  return <MomentFormattedText {...props} format="LT" />;
};

export const DateTimeText = (props: DateProps) => {
  return <MomentFormattedText {...props} format="YYYY-MM-DD HH:mm" />;
};

export const RelativeTimeText = (props: DateProps) => {
  if (!props.date) {
    return props.missing ? <Text>{props.missing}</Text> : null;
  }
  return <Moment element={Text} date={props.date} fromNow />;
};

type MoneyProps = {
  value?: number | null | undefined;
  currency?: string | null | undefined;
  emptyZero?: boolean;
  missing?: string;
  precision?: number;
};

export const MoneyText = ({
  value,
  currency,
  emptyZero,
  missing,
  precision,
}: MoneyProps) => {
  if (value === undefined || value === null || (emptyZero && value === 0)) {
    return missing ? <Text>{missing}</Text> : null;
  }
  const currencyStr = currency === "USD" ? "$" : currency ?? "$";
  const amount = value.toFixed(precision ?? 2);
  return (
    <Text>
      {currencyStr}
      {amount}
    </Text>
  );
};
