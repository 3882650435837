// These are just the default font sizes for Tamagui.
export const fontSize = {
  1: 11,
  2: 12,
  3: 13,
  4: 14,
  true: 14, // Paragraph
  5: 16, // H6
  6: 18, // H5
  7: 20, // H4
  8: 23, // H3
  9: 30, // H2
  10: 46, // H1
  11: 55,
  12: 62,
  13: 72,
  14: 92,
  15: 114,
  16: 134,
};
