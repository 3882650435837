import React from "react";
import { Dimensions, type OpaqueColorValue } from "react-native";
import { type ColorTokens, Spinner, type SpinnerProps, YStack } from "tamagui";

import { type GetThemeValueForKey, type StackProps } from "@tamagui/web";

export const FullscreenSpinner = (props: SpinnerProps) => {
  return (
    <YStack flex={1} justifyContent="center" alignItems="center">
      <Spinner {...props} />
    </YStack>
  );
};

/**
 * Similar to fullscreenspinner, this should be used when we need to overlay the
 * entire screen with a spinner while a component/components render beneath
 * it. Commons use cases would be iFrames or scroll views with a lot of content
 *
 * We should possibly look into making this a more generic overlay component in
 * a provider that can be easily triggered from anywhere in the app and is
 * therefore not subject to h & w of parent components
 */
type FullscreenSpinnerOverlayProps = {
  props?: SpinnerProps;
  heightOffset?: number;
  overlayColor?:
    | ColorTokens
    | OpaqueColorValue
    | GetThemeValueForKey<"backgroundColor">;
  stackProps?: StackProps;
};

export const FullscreenSpinnerOverlay = ({
  props,
  heightOffset,
  overlayColor,
  stackProps,
}: FullscreenSpinnerOverlayProps) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  return (
    <YStack
      flex={1}
      justifyContent="center"
      alignItems="center"
      height={windowHeight}
      width={windowWidth}
      backgroundColor={overlayColor}
      zIndex={1000}
      opacity={1}
      position="absolute"
      {...stackProps}
    >
      <Spinner
        {...props}
        position={heightOffset ? "relative" : "unset"}
        y={heightOffset || "unset"}
      />
    </YStack>
  );
};
