import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

import { trackEmailClick } from "./utils";

// Used to track email link clicks for deep links that are not handled by CustomerIO.
export const EmailLinkAnalyticsTracker = () => {
  const searchParams = useSearchParams();
  const linkId = searchParams.get("cio_link_id");

  useEffect(() => {
    if (typeof linkId === "string" && linkId.length > 0) {
      void trackEmailClick(linkId);
    }
  }, [linkId]);

  return null;
};
